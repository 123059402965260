import { lazy } from "react";

export const Login = lazy(() => import("./Login"));
export const Dashboard = lazy(() => import("./Dashboard"));
export const ManageUsers = lazy(() => import("./ManageUsers"));
export const ManageActivityList = lazy(() => import("./ManageActivityList"));
export const ManageCategories = lazy(() => import("./ManageCategories"));
export const ManageEvents = lazy(() => import("./ManageEvents"));
export const ManageServices = lazy(() => import("./ManageServices"));
export const ManageCrowd = lazy(() => import("./ManageCrowd"));
export const ManageGenre = lazy(() => import("./ManageGenre"));
export const ManageDays = lazy(() => import("./ManageDays"));
export const AppUpdate = lazy(() => import("./AppUpdate"));
export const Bookings = lazy(() => import("./Bookings"));
export const ArtistBookingList = lazy(() => import("./ArtistBookingList"));
export const ManageLanguage = lazy(() => import("./ManageLanguage"));
export const ManageRequests = lazy(() => import("./ManageRequests"));
export const Supports = lazy(() => import("./Supports"));
export const ManageReviews = lazy(() => import("./ManageReviews"));
export const ManagePromoCodes = lazy(() => import("./ManagePromoCodes"));
export const ManageArtists = lazy(() => import("./ManageArtists"));
export const ManageManagers = lazy(() => import("./ManageManagers"));
export const ChangePassword = lazy(() => import("./ChangePassword"));
export const ForgotPassword = lazy(() => import("./ForgotPassword"));
export const EditProfile = lazy(() => import("./EditProfile"));
export const Notifications = lazy(() => import("./Notifications"));
export const Users = lazy(() => import("./Users"));
export const EventDuration = lazy(() => import("./EventDuration"));
export const ManageRefunds = lazy(() => import("./ManageRefunds"));
export const BookingCharge = lazy(() => import("./BookingCharge"));
export const ManageAdminManagers = lazy(() => import("./ManageAdminManager"));
export const PayInCash = lazy(() => import("./PayInCash"));
