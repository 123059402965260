import {
  AcUnit,
  AttractionsTwoTone,
  BookOutlined,
  CalendarToday,
  Category,
  Dashboard,
  HomeRepairService,
  Language,
  MoneyOff,
  // LocalActivity,
  Notifications,
  People,
  // PersonAdd,
  Redeem,
  Reviews,
  Support,
  SystemUpdate,
  Timer,
  ManageAccounts,
  // TheaterComedy,
  VpnKey,
  SupervisedUserCircle,
  Money,
} from "@mui/icons-material";
import { useAppContext } from "contexts";
import React from "react";
const useMenuItems = () => {
  const { user, permission } = useAppContext();

  const Arr = [
    // user?.role==="admin-manager"&&permission?.
    // true &&
    {
      key: "1",
      title: "Dashboard",
      icon: <Dashboard className="iconColor" />,
      route: "/",
      isVisible: true,
    },
    // {
    //   key: "11",
    //   title: "Manage  Managers",
    //   icon: <SupervisedUserCircleOutlined className="iconColor" />,
    //   route: "/manage-managers",
    // },

    {
      key: "3",
      icon: <People className="iconColor" />,
      title: "Manage Users",
      route: "/manage-users",
      isVisible:
        user?.role === "admin-manager"
          ? Boolean(permission?.isUserOperation)
          : true,
    },
    {
      key: "3",
      icon: <SupervisedUserCircle className="iconColor" />,
      title: "Admin Managers",
      route: "/admin-managers",
      isVisible: user?.role === "admin-manager" ? false : true,
    },
    {
      key: "24",
      icon: <ManageAccounts className="iconColor" />,
      title: "Manage Managers",
      route: "/manage-managers",
      isVisible:
        user?.role === "admin-manager"
          ? Boolean(permission?.isUserOperation)
          : true,
    },
    {
      key: "2",
      title: "Manage Artists",
      icon: <AcUnit className="iconColor" />,
      route: "/manage-artists",
      isVisible:
        user?.role === "admin-manager"
          ? Boolean(permission?.isUserOperation)
          : true,
    },
    // {
    //   key: "14",
    //   icon: <PersonAdd className="iconColor" />,
    //   title: "Manage Requests",
    //   route: "/manage-requests",
    // },
    {
      key: "4",
      icon: <Category className="iconColor" />,
      title: "Manage Categories",
      route: "/manage-categories",
      isVisible:
        user?.role === "admin-manager"
          ? Boolean(permission?.isAllBookingOperation)
          : true,
    },
    {
      key: "22",
      icon: <AttractionsTwoTone className="iconColor" />,
      title: "Manage Events",
      route: "/manage-events",
      isVisible:
        user?.role === "admin-manager"
          ? Boolean(permission?.isAllBookingOperation)
          : true,
    },
    {
      key: "25",
      icon: <Timer className="iconColor" />,
      title: "Event Duration",
      route: "/event-duration",
      isVisible:
        user?.role === "admin-manager"
          ? Boolean(permission?.isAllBookingOperation)
          : true,
    },
    {
      key: "11",
      icon: <HomeRepairService className="iconColor" />,
      title: "Manage Services",
      route: "/manage-services",
      isVisible:
        user?.role === "admin-manager"
          ? Boolean(permission?.isAllBookingOperation)
          : true,
    },
    // {
    //   key: "12",
    //   icon: <TheaterComedy className="iconColor" />,
    //   title: "Manage Genres",
    //   route: "/manage-genres",
    // },
    {
      key: "13",
      icon: <Language className="iconColor" />,
      title: "Manage Languages",
      route: "/manage-languages",
      isVisible:
        user?.role === "admin-manager"
          ? Boolean(permission?.isAllBookingOperation)
          : true,
    },

    {
      key: "7",
      icon: <Redeem className="iconColor" />,
      title: "Manage PromoCodes",
      route: "/manage-promo-codes",
      isVisible:
        user?.role === "admin-manager"
          ? Boolean(permission?.isPromoCodeOperation)
          : true,
    },
    {
      key: "5",
      icon: <People className="iconColor" />,
      title: "Manage Crowd",
      route: "/manage-crowd",
      isVisible:
        user?.role === "admin-manager"
          ? Boolean(permission?.isAllBookingOperation)
          : true,
    },
    {
      key: "16",
      icon: <CalendarToday className="iconColor" />,
      title: "Manage Days",
      route: "/manage-days",
      isVisible:
        user?.role === "admin-manager"
          ? Boolean(permission?.isAllBookingOperation)
          : true,
    },
    {
      key: "35",
      icon: <BookOutlined className="iconColor" />,
      title: "All Bookings",
      route: "/all-bookings",
      isVisible:
        user?.role === "admin-manager"
          ? Boolean(permission?.isAllBookingOperation)
          : true,
    },
    {
      key: "15.1",
      icon: <Money className="iconColor" />,
      title: "Pay In Cash",
      route: "/pay-in-cash",
      isVisible:
        user?.role === "admin-manager"
          ? Boolean(permission?.isAllBookingOperation)
          : true,
    },
    {
      key: "15",
      icon: <MoneyOff className="iconColor" />,
      title: "Manage Refunds",
      route: "/manage-refunds",
      isVisible:
        user?.role === "admin-manager"
          ? Boolean(permission?.isAllBookingOperation)
          : true,
    },
    {
      key: "8",
      icon: <Support className="iconColor" />,
      title: "Supports",
      route: "/supports",
      isVisible:
        user?.role === "admin-manager"
          ? Boolean(permission?.isSupportOperation)
          : true,
    },
    {
      key: "6",
      icon: <Reviews className="iconColor" />,
      title: "Manage Reviews",
      route: "/manage-reviews",
      isVisible:
        user?.role === "admin-manager"
          ? Boolean(permission?.isReviewOperation)
          : true,
    },
    {
      key: "14",
      icon: <SystemUpdate className="iconColor" />,
      title: "Configs",
      route: "",
      isVisible:
        user?.role === "admin-manager"
          ? Boolean(permission?.isConfigOperation)
          : true,
      submenus: [
        {
          key: "1",
          title: "App Update",
          icon: <SystemUpdate className="iconColor" />,
          route: "/app-update",
        },
        {
          key: "2",
          title: "Booking Charge",
          icon: <MoneyOff className="iconColor" />,
          route: "/set-booking-charge",
        },
      ],
    },
    // {
    //   key: "14",
    //   icon: <SystemUpdate className="iconColor" />,
    //   title: "App Update",
    //   route: "/app-update",
    // },
    {
      key: "9",
      icon: <Notifications className="iconColor" />,
      title: "Notifications",
      route: "/notifications",
      isVisible:
        user?.role === "admin-manager"
          ? Boolean(permission?.isNotificationOperation)
          : true,
    },
    {
      key: "10",
      icon: <VpnKey className="iconColor" />,
      title: "Change Password",
      route: "/change-password",
      isVisible: true,
    },
  ];
  const MenuItems = Arr?.filter((item) => item?.isVisible);
  return MenuItems;
};

export default useMenuItems;
