import { useContext } from "react";
import { AppContext } from "./AppContextProvider";

const useAppContext = () => {
  const {
    user,
    setUser,
    login,
    logout,
    NotifcationRealtime,
    not,
    fetchFun,
    permission,
  } = useContext(AppContext);

  return {
    user,
    setUser,
    login,
    logout,
    NotifcationRealtime,
    not,
    fetchFun,
    permission,
  };
};

export default useAppContext;
