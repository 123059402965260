import { BASE_URL } from "configs";
// import { useIsMounted } from "hooks";
import Swal from "sweetalert2";
import { useEffect } from "react";
const { createContext, useState } = require("react");

export const AppContext = createContext();

const AppContextProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [permission, setPermission] = useState({});
  const [not, setNot] = useState([]);
  // const { isMounted } = useIsMounted();
  // const NotificationReal = (RealTime) => {
  //   RealTime();
  // };
  const login = async (email, password, submitProps) => {
    try {
      const result = await fetch(`${BASE_URL}/auth/login`, {
        method: "POST",
        body: JSON.stringify({
          email: email,
          password: password,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const res = await result.json();

      submitProps.resetForm();
      if (result.status === 200) {
        if (
          res?.success?.user?.role === "admin" ||
          res?.success?.user?.role === "admin-manager"
        ) {
          setPermission(res?.success?.permission);
          window.localStorage.setItem("SAL", res?.success?.data);
          setUser(res?.success?.user);
        } else {
          Swal.fire({
            icon: "error",
            text: "Please Login as SuperAdmin Or Admin",
          });
          submitProps.resetForm();
          return;
        }

        // res?.success?.user?.role==="admin-manager" && setPermission()
        // console.log(res?.success?.data);
      } else {
        Swal.fire({ icon: "error", text: res.error.message });
      }
      // document.cookie = res?.data;
    } catch (error) {
      submitProps.resetForm();
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchFun = async () => {
      const Sal = window.localStorage.getItem("SAL");
      if (!Sal) return;
      try {
        const result = await fetch(`${BASE_URL}/user/accounts-self`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Beear ${Sal}`,
          },
        });
        const res = await result.json();
        // console.log(res);
        if (result?.status === 200) {
          setUser(res?.success?.data);
          console.log(res?.success);
          setPermission(res?.success?.permission);
        } else {
          window.localStorage.removeItem("SAL");
          setUser({});
          window.location.replace("/");
          console.log(res?.error?.message);
          // Swal.fire({ icon: "error", text: res.error.message });
        }

        // isMounted.current && setUser();
        // document.cookie = res?.data;
      } catch (error) {
        console.log(error);
      }
    };
    fetchFun();
  }, []);
  const logout = () => {
    try {
      window.localStorage.removeItem("SAL");
      setUser({});
      window.location.replace("/");
    } catch (error) {
      new Error(error);
    }
  };
  const fetchFun = async () => {
    try {
      const result = await fetch(
        `${BASE_URL}/notification/all-notification/${user?._id}`
      );
      const res = await result.json();
      // console.log(res);
      result.status === 200
        ? console.log(res?.success?.data)
        : Swal.fire({ icon: "error", text: res.error.message });
      return setNot(res?.success?.data);
      // isMounted.current && setUser();
      // document.cookie = res?.data;
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const fetchFun = async () => {
      try {
        const result = await fetch(
          `${BASE_URL}/notification/all-notification/${user?._id}`
        );
        const res = await result.json();
        // console.log(res);
        // result.status === 200
        //   ? console.log(res?.success?.data)
        //   : Swal.fire({ icon: "error", text: res.error.message });
        return setNot(res?.success?.data);
        // isMounted.current && setUser();
        // document.cookie = res?.data;
      } catch (error) {
        console.log(error);
      }
    };
    // isMounted.current && setUser();
    // document.cookie = res?.data;

    fetchFun();
  }, [user?._id]);
  return (
    <AppContext.Provider
      value={{ user, setUser, login, logout, not, fetchFun, permission }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
